export default {
  "oceanbase-playground.src.pages.OceanBaseWithFlink.CarOrderSystemDemo": "Car Ordering Demo",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OceanBaseReadonlyColumnStoreReplica": "Readonly Column Store Replica",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.ReadonlyColumnStoreReplica": "Readonly Column Store Replica",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RowStoreReplica": "Row Store Replica",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealtimeOrderDashboard": "Dashboard",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealtimeExecuteSql": "SQL",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Username": "Username",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.PleaseEnterUsername": "Please enter username",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OnlySupportSpecificCharacter": "Only support Chinese & English character, number and underline",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.ChineseNameCannotOverFourCharacters": "Chinese name cannot exceed 4 characters",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.UserNameCannotOverTenCharacters": "User name cannot exceed 10 characters",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.GenerateRandomly": "Generate",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderCount": "Quantity",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderColor": "Color",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderSubmit": "SUBMIT YOUR ORDER",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.DataVisualization": "Data Visualization",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.TotalOrderCount": "Number of Orders",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.SqlLatency": "SQL Execution Time: {latency}ms",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Top3ColorsOfToday": "Top3 Colors of Today",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderCountOfToday": "Order Count of Today",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealTimeOrders": "Real Time Orders",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.OrderSuccess": "Place order successfully",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealTimeOrderSuccess": "{customerName} placed an order",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.RealTimeCarColor": "{color} {count} cars",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Blue": "Blue",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Black": "Black",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Yellow": "Orange",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Red": "Red",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.White": "White",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Green": "Green",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Purple": "Purple",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count1": "1",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count10": "10",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count100": "100",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count1000": "1000",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.Count10000": "10000",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.ScanQrCodeToTry": "Scan QR code to try!",
  "oceanbase-playground.src.pages.OceanBaseWithFlink.SupportMultiplePeopleOrder": "Online and Concurrent",
};
