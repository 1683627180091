// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
// defineApp
export { defineApp } from './core/defineApp'
export type { RuntimeConfig } from './core/defineApp'
// plugins
export { addLocale, setLocale, getLocale, getIntl, useIntl, injectIntl, formatMessage, FormattedMessage, getAllLocales, FormattedDate, FormattedDateParts, FormattedDisplayName, FormattedHTMLMessage, FormattedList, FormattedNumber, FormattedNumberParts, FormattedPlural, FormattedRelativeTime, FormattedTime, FormattedTimeParts, IntlProvider, RawIntlProvider, SelectLang } from '/vercel/path0/src/.umi-production/plugin-locale';
export { useRequest, UseRequestProvider, request, getRequestInstance } from '/vercel/path0/src/.umi-production/plugin-request';
// plugins types.d.ts
export * from '/vercel/path0/src/.umi-production/plugin-request/types.d';
// @umijs/renderer-*
export { createBrowserHistory, createHashHistory, createMemoryHistory, Helmet, HelmetProvider, createSearchParams, generatePath, matchPath, matchRoutes, Navigate, NavLink, Outlet, resolvePath, useLocation, useMatch, useNavigate, useOutlet, useOutletContext, useParams, useResolvedPath, useRoutes, useSearchParams, useAppData, useClientLoaderData, useLoaderData, useRouteProps, useSelectedRoutes, useServerLoaderData, renderClient, __getRoot, Link, useRouteData, __useFetcher, withRouter } from '/vercel/path0/node_modules/.pnpm/@umijs+renderer-react@4.3.22_react-dom@18.3.1_react@18.3.1/node_modules/@umijs/renderer-react';
export type { History, ClientLoader } from '/vercel/path0/node_modules/.pnpm/@umijs+renderer-react@4.3.22_react-dom@18.3.1_react@18.3.1/node_modules/@umijs/renderer-react'
// umi/client/client/plugin
export { ApplyPluginsType, PluginManager } from '/vercel/path0/node_modules/.pnpm/umi@4.3.22_@babel+core@7.25.2_@types+node@20.16.5_@types+react@18.3.8_eslint@8.57.1_prettier@_47zres6iqppka7bumxntdevmhe/node_modules/umi/client/client/plugin.js';
export { history, createHistory } from './core/history';
export { terminal } from './core/terminal';
// react ssr
export const useServerInsertedHTML: Function = () => {};
