// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","id":"1"},"2":{"path":"oceanbase-with-flink/order","parentId":"1","id":"2"},"3":{"path":"/","parentId":"1","id":"3"},"4":{"path":"/","redirect":"/oceanbase-with-flink","parentId":"3","id":"4"},"5":{"path":"oceanbase-with-flink","parentId":"3","id":"5"},"6":{"path":"readonly-column-store-replica","parentId":"3","id":"6"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import(/* webpackChunkName: "p__Layout__index" */'@/pages/Layout/index.tsx')),
'2': React.lazy(() => import(/* webpackChunkName: "p__OceanBaseWithFlink__Order" */'@/pages/OceanBaseWithFlink/Order.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Layout__BasicLayout__index" */'@/pages/Layout/BasicLayout/index.tsx')),
'4': React.lazy(() => import('./EmptyRoute')),
'5': React.lazy(() => import(/* webpackChunkName: "p__OceanBaseWithFlink__index" */'@/pages/OceanBaseWithFlink/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__OceanBaseWithFlink__index" */'@/pages/OceanBaseWithFlink/index.tsx')),
},
  };
}
